import axios from 'axios';

export const $axios = axios.create({
    baseURL: 'https://numsadmissionform.cmhlmc.com/backend/',//live/'https://numsadmissionform.cmhlmc.com/backend/','http://192.168.0.249:8091/'
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

// export const $axios = $ax.interceptors.response.use(function (response) {
//         console.log(response);
//         // Any status code within the range of 2xx cause this function to trigger
//         // Do something with response data
//         return response;
//     }, function (error) {
//         console.log(error);
//         alert("errr");
//         // Any status codes outside the range of 2xx cause this function to trigger
//         // Do something with response error
//         return Promise.reject(error);
// });