export default {
    install: (app) => {
        app.mixin({
            data() {
                return { 
                    adsf: 'dd',
                };
            },
            mounted(){
                // this.$emit.on('scaleChanged', (scale) => {
                //     alert(scale);
                // })
            },
            methods:{ 
                scaleChanged(scale){
                    alert(scale);
                },
                customError(error) { 
                    
                    var response = '';
                    if(error.response !== undefined){
                        if(error.response.data !== undefined){
                            if(error.response.data.message !== undefined){
                                if (error.response.data.message) { 
                                    response = error.response.data.message
                                    console.log('running...5');
                                }
                            }
                            else{
                                response = error.response.data;
                            }
                        }
                        else{
                            response = error.response;
                        }
                    } else if (error.request !== undefined) {
                        if(error.request){
                            response = error.request;
                        } 
                        console.log('running...2');
                    }else if (error.message !== undefined) {
                        if (error.message){
                            response = error.message; 
                        } 
                        console.log('running...1');
                    } else {
                        response = error;  
                    }
                    
                   
                    var user_network_error = response.includes("unreachable network");
                    var server_network_error = response.includes("established connection failed");
                    var server_connection_error = response.includes("connection attempt failed");
                    var server_reachability_error = response.includes("network error");
                    var JWT_auth_token_error = response.includes("Lcobucci");
                    if (user_network_error > 0) {
                        response = 'No internet connection!';
                    } else if (server_network_error > 0) {
                        response = 'Web server is unreachable! Please contact IT department';
                    } else if (server_reachability_error > 0 || response == 'network error') {
                        response = 'Network Error! Server is not reachable.';
                    } else if (server_connection_error > 0) {
                        response = 'Web server connectivity problem! Please contact IT department';
                    } else if (JWT_auth_token_error > 0) {
                        response = 'JWT Authuntication failed. Please reload page!';
                    }  
                    return response;
                },
                shortName(name) {
                    name = this.removeDoubleSpace(name);
                    var gn = name.split(' ').map(x => x[0]).join('');
        
                    if (gn.length > 2) {
                        gn = gn[0] + gn[1];
                    }
                    return gn;
                },
                removeDoubleSpace(str) {
                    return str.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
                },
                shortStr(str, start = 0, end = 50) {
                    if(str){
                        var eclips = '';
        
                        if (str.length > end) {
                            eclips = '...';
                        }
                        return str.substr(start, end) + eclips;
                    }

                    return str;
                    
                },
                formatDate(value) {
                    var date = new Date(value)
                    return date.toLocaleDateString('en-US', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric', 
                    });
                },
                zeroPad(value, length) {
                    // Convert the value to a string
                    let strValue = value.toString();
    
                    // Calculate the number of zeros to add
                    let zerosToAdd = length - strValue.length;
    
                    // Add the zeros to the beginning of the string
                    let paddedValue = '0'.repeat(zerosToAdd) + strValue;
    
                    return paddedValue;
                },
                formatDateTime(value) {
                    var date = new Date(value)
                    return date.toLocaleDateString('en-US', {
                        hour12: false,
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit', 
                    });
                },
                showMessage(msg,type,time=5000){
                    var Vthis = this; 
                    return this.$toast.add({
                        severity:type,
                        position: 'top-right', 
                        pauseOnHover : true, 
                        summary: type.charAt(0).toUpperCase() + type.slice(1)+' Message!', 
                        detail:Vthis.shortStr(Vthis.customError(msg),0,100), 
                        life: time
                    });  
                },
                htmlparser_data(questionString)
                {
                    var html = questionString;
                    var div = document.createElement("div");
                    div.innerHTML = html;
                    var text = div.textContent || div.innerText || "";
                    return text;
                },
                downloadFile(file)
                {
                    var finalUrl=this.studentFiles+file;
                    window.open(finalUrl);
                },
            },
        });
    },
};