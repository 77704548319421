import { createRouter, createWebHistory } from 'vue-router';
// console.log(this.menu);
const routes = [
    // {
    //     path:'/leave-applications',
    //     name:'leave-applications',
    //     component: () => import('./pages/LeaveApplications/LeaveApplications.vue')
    // },{
    //     path:'/hostel-students',
    //     name:'hostel-students',
    //     component: () => import('./pages/Students/Students.vue')
    // },
    {
        path: '/',
        name: 'home',
        component: () => import('./pages/Dashboards/Dashboard.vue'),
        meta: {
            breadcrumb: [{ label: 'Home' }],
        }
    },
    {
        name: 'exam',
        path: '/exam',
        component: () => import('./pages/Exam/Exam.vue'),
        meta: {
            breadcrumb: [{ label: 'Exam' }],
        }
    },
    {
        name: 'setSubjects',
        path: '/set-subjects',
        component: () => import('./pages/SetSubjects/SetSubjects.vue'),
        meta: {
            breadcrumb: [{ label: 'Subjects & Papers' }],
        }
    },
    {
        name: 'setFees',
        path: '/set-fees',
        component: () => import('./pages/SetSubjects/SetFees.vue'),
        meta: {
            breadcrumb: [{ label: 'Fees Setting' }],
        }
    },
   
    // {
    //     name: 'dashboardRoute',
    //     path: '/',
    //     component: () => import('./pages/Dashboards/Dashboard.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Home', label: 'Dashboard' }],
    //     }
    // },
    {
        name: 'applications',
        path: '/applications',
        component: () => import('./pages/Applications/Applications.vue'),
        meta: {
            breadcrumb: [{ label: 'Applications' }],
        }
    },
    {
        name: 'Initialization',
        path: '/initialization',
        component: () => import('./pages/Initialization/Initialization.vue'),
        meta: {
            breadcrumb: [{ label: 'EAFs' }],
        }
    },
    {
        name: 'Student Applications',
        path: '/student-applications',
        component: () => import('./pages/StudentApplications/StudentApplications.vue'),
        meta: {
            breadcrumb: [{ label: 'Student Applications' }],
        }
    },
   
    {
        name: 'nums-registrations',
        path: '/nums-registrations',
        component: () => import('./pages/NUMS/Registrations.vue'),
        meta: {
            breadcrumb: [{ label: 'NUMS-Registration' }],
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('./pages/Logout.vue'),
        meta: {
            breadcrumb: [{ label: 'Logout' }],
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    // {
    //     path: '/register',
    //     name: 'register',
    //     component: () => import('./pages/Register.vue')
    // },

    // {
    //     name: 'settings',
    //     path: '/settings',
    //     component: () => import('./pages/Settings.vue'),
    //     meta: {
    //         breadcrumb: [{ parent: 'Profile Settings', label: 'Settings' }],
    //     }
    // }, 
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    }
});

export default router;