<template>
    <div class="pages-body login-page flex flex-column">
        <div class="align-self-center mt-auto mb-auto col-12 lg:col-3 sm:col-8">
            <div class="pages-panel card flex flex-column">
                <div class="pages-header px-3 py-1">
                    <h2>{{ appName }}</h2>
                </div>
                <h4>Welcome</h4>
                <div class="pages-detail mb-6 px-10">Sign-in to {{ appName }}</div>
                <form @submit.prevent="login" @keydown="form.onKeydown($event)">
                    <div v-if="errorMessage" v-html="errorMessage" class="field p-inline-message p-component p-inline-message-error" />
                    <div class="input-panel flex flex-column px-0">
                        <div class="field">
                            <span class="p-float-label">
                                <InputText type="text" id="employee_id" v-model="form.employee_id" :class="form.errors.has('employee_id') ? 'p-invalid' : ''" />
                                <label for="employee_id">Employee ID</label>
                            </span>
                            <HasError class="p-error" :form="form" field="employee_id" />
                        </div>
                        <div class="field mb-6">
                            <span class="p-float-label">
                                <InputText type="password" id="password" v-model="form.password" />
                                <label for="password">Password</label>
                            </span>
                            <HasError class="p-error" :form="form" field="password" />
                        </div>
                    </div>
                    <Button type="submit" class="mr-2 mb-2 p-button-lg" :disabled="form.busy" icon="pi pi-sign-in" label="LOGIN" :loading="loading" />
                    <!-- <router-link to="/register">
                        <p class="text-center">Register</p>
                    </router-link> -->
                </form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: new this.Form({
                employee_id: '',
                password: '',
            }),
            loading: false,
            errorMessage: '',
            pagePath: document.URL,
        };
    },
    mounted() {
        if (localStorage.getItem('token')) {
            this.$router.push('/');
        }
    },
    methods: {
        async login() {
            var Vthis = this;
            Vthis.loading = true;
            await this.form
                .post(Vthis.$baseurl + 'api/auth/login', Vthis.form)
                .then((response) => {
                    console.log(response.data.user);
                    localStorage.setItem('token', response.data.access_token);
                    localStorage.setItem('user', JSON.stringify(response.data.user));
                    Vthis.$router.go('/');
                })
                .catch((error) => {
                    console.log(error.response.data);
                })
                .finally(() => {
                    Vthis.loading = false;
                });
        },
    },
};
</script>

<style scoped></style>
